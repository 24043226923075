import React, { useEffect, useRef, useState } from 'react';
import BlockComponent from './BlockComponent';
import { findIndex } from 'lodash';
import { getComponentsForContentType } from '../../../utils';
import BlockComponentCustom from './BlockComponentCustom';
import { useDrag, useDrop } from 'react-dnd';
import { DND_TYPES } from '../../../constants';

const BlockContainer = ({ setDraggingIndex, draggingIndex, block, index, context, onEditContent, onDelete, onMoveUp, onMoveDown, onChangeBlockVariant, onDrop }) => {
  const [hovered, setHovered] = useState(false);
  const contentTypeComponents = getComponentsForContentType(block.contentType);

  const dropContainerRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: DND_TYPES.PAGE_COMPONENT,
    item: () => {
      return { index, component: block, originalIndex: index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),

    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        onDrop({ addedIndex: item.originalIndex, removedIndex: item.index, payload: item.component });
      }
      setDraggingIndex(null);
    }
  });

  useEffect(() => {
    if (isDragging && draggingIndex == null) setDraggingIndex(index);
  }, [isDragging]);

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: [DND_TYPES.LIST_COMPONENT, DND_TYPES.PAGE_COMPONENT],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver()
      };
    },
    hover(item, monitor) {
      if (!dropContainerRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (!item.setDraggingIndex) {
        // Hack to be able to setDragging index from the ComponentMenu
        item.setDraggingIndex = setDraggingIndex;
      }
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropContainerRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (dragIndex === undefined) {
        // first time hovering on the page...
        onDrop({ addedIndex: hoverIndex, removedIndex: null, payload: item.component });
      } else {
        onDrop({ addedIndex: hoverIndex, removedIndex: item.index, payload: item.component });
      }
      setDraggingIndex(hoverIndex);
      item.index = hoverIndex;
    }
  });

  const handleChangeComponentVariant = () => {
    const currentIndex = findIndex(contentTypeComponents, (o) => o.name === block.componentName);
    const nextIndex = currentIndex + 1 < contentTypeComponents.length ? currentIndex + 1 : 0;
    const variant = contentTypeComponents[nextIndex];
    onChangeBlockVariant(block.id, variant.name);
  };

  drag(drop(dropContainerRef));
  return (
    <div ref={dropContainerRef} className='builder-relative' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {draggingIndex === index && <div className='builder-absolute builder-inset-0 builder-bg-slate-400 builder-z-9999' />}
      {hovered && !isOver && !isDragging && (
        <div className='builder-absolute builder-border builder-border-blue-600 builder-cursor-move builder-top-0 builder-left-0 builder-right-0 builder-bottom-0' style={{ zIndex: 9999 }}>
          <div className='builder-flex builder-flex-row builder-m-2 builder-justify-between builder-text-xs'>
            <div className='builder-flex builder-flex-row builder-text-xs'>
              {contentTypeComponents.length > 1 && (
                <div className='builder-bg-primary builder-mr-2 builder-p-2 builder-cursor-pointer builder-text-white builder-rounded builder-text-center' style={{ minWidth: 40 }} onClick={handleChangeComponentVariant}>
                  <i className='fal fa-arrow-left' />
                </div>
              )}

              {contentTypeComponents.length > 1 && (
                <div className='builder-bg-primary builder-p-2 builder-cursor-pointer builder-text-white builder-rounded builder-text-center' style={{ minWidth: 40 }} onClick={handleChangeComponentVariant}>
                  <i className='fal fa-arrow-right' />
                </div>
              )}
            </div>
            <div className='builder-flex builder-flex-row builder-justify-end builder-text-xs'>
              <div className='builder-bg-primary builder-mr-2 builder-p-2 builder-cursor-pointer builder-text-white builder-rounded builder-flex' onClick={() => onEditContent(block.id)}>
                <div onClick={() => onMoveUp(block.id)} className='builder-flex builder-flex-1 builder-items-center builder-justify-center builder-text-xs' style={{ width: 20 }}>
                  <i className='fal fa-arrow-up mr-2'></i>
                </div>
                <div onClick={() => onMoveDown(block.id)} className='builder-flex builder-flex-1 builder-items-center builder-justify-center builder-text-xs' style={{ width: 20 }}>
                  <i className='fal fa-arrow-down'></i>
                </div>
              </div>

              <div className='builder-bg-primary builder-mr-2 builder-p-2 builder-cursor-pointer builder-text-white builder-rounded' onClick={() => onEditContent(block.id)}>
                <i className='fal fa-font-case' /> <span className='font-medium text-xs ml-1'>Content</span>
              </div>

              <div className='builder-bg-primary builder-p-2 builder-cursor-pointer builder-text-white builder-rounded' onClick={() => onDelete(block.id)}>
                <i className='fas fa-trash-alt' />
              </div>
            </div>
          </div>
        </div>
      )}

      {block.contentType !== 'CustomGeneratedBlockInBuilder' && <BlockComponent block={block} context={context} />}

      {block.contentType === 'CustomGeneratedBlockInBuilder' && <BlockComponentCustom block={block} context={context} />}
    </div>
  );
};

export default BlockContainer;
