import React from 'react';
import App from './App';
import './css/app.css';
import 'react-toggle/style.css';
import { ClientContext } from 'graphql-hooks';
import { client } from './graphqlClient';
import { createRoot } from 'react-dom/client';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <I18nextProvider i18n={i18n}>
    <ClientContext.Provider value={client}>
      <App />
    </ClientContext.Provider>
  </I18nextProvider>
);
