import React from 'react';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { useHistory, Link } from 'react-router-dom';

const PageHeader = ({ onSave, page, saving, errors, context }) => {
  return (
    <div className='builder-w-full builder-bg-gray-100 builder-py-2 builder-mb-5 builder-sticky builder-top-0 builder-z-9999'>
      <div className=' builder-flex builder-flex-row builder-px-5 builder-items-center' style={{ width: `calc(100vw - 300px)` }}>
        <div className={`builder-flex builder-flex-row builder-items-center ${saving ? 'builder-bg-gray-100 builder-animate-pulse' : ''}`}>
          <div className='builder-flex builder-flex-col builder-mr-4'>
            <div className='builder-text-2xl builder-text-black builder-font-bold'>Page {page.isDraft ? '[Draft]' : ''}</div>
            <div>
              Path: <span className='builder-font-semibold'>{page?.url}</span>
              <span className='builder-mx-2'>|</span>
              Type: <span className='builder-font-semibold'>{page?.type}</span>
            </div>
          </div>
        </div>
        <div className='builder-ml-auto builder-flex builder-space-x-4 builder-items-center'>
          <Link to={`/preview/${page.id}`} target='_blank' className='builder-flex builder-items-center builder-px-4 builder-py-2 builder-transition-all builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500'>
            <i className={`fas fa-eye mr-2`} />
            Preview
          </Link>
          <ButtonWithIndicator
            dataTestId='page-details-add-button'
            loading={saving}
            disabled={saving || Object.keys(errors).length !== 0}
            onClick={onSave}
            icon='fal fa-save'
            text='Save'
            colorClass='builder-bg-primary builder-text-white'
            borderClass='builder-border builder-border-bg-blue-500'
            className='builder-ml-0 builder-mr-0'
          />
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
