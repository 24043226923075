import { useContext } from 'react';
import { useMutation } from 'graphql-hooks';
import { DispatchContext } from '../../App';
import { setSession, stateReset } from '../data/actions';
import { resetSessionToDefault } from '../data/session';
import { routes } from '../data/constants';
import { useHistory } from 'react-router-dom';
import { useTrackingUsers } from '../session/useTrackingUsers';

const SIGNOUT = `
    mutation Logout($application: Int!) {
        logout(application: $application)  {
           ok          
        }
    }`;

export function useSignOut(_dispatch = undefined, _) {
  const [signoutMutation] = useMutation(SIGNOUT);

  const dispatch = useContext(DispatchContext);
  const { userLeft } = useTrackingUsers();

  let history = useHistory();

  const handleSignOut = (redirect = true) => {
    userLeft();
    return signoutMutation({
      variables: {
        application: 0
      }
    }).then((result) => {
      setSession(_dispatch || dispatch, resetSessionToDefault());
      stateReset(_dispatch || dispatch);
      if (redirect && history) history.push(routes.LOGIN);
      else if (redirect) window.location.href = routes.LOGIN;
    });
  };

  return {
    signOut: (redirect = true) => {
      return handleSignOut(redirect);
    }
  };
}
