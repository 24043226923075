import React, { useEffect } from 'react';
import Header from './Header';
import HeaderMenu from '../features/builder/components/HeaderMenu';
import ComponentMenu from '../features/builder/components/ComponentMenu';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useLockedState from '../features/session/useLockedState';
import { useTrackingUsers } from '../features/session/useTrackingUsers';

const EditorTemplate = ({ children, onChangeEnvironment, context }) => {
  const { lockedState } = useLockedState();

  const { isReleasing, userMoved } = useTrackingUsers();
  useEffect(() => {
    userMoved();
  }, [window.location.pathname]);

  return (
    <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
      {/*HEADER*/}
      <Header renderMenu={() => <HeaderMenu isReleasing={isReleasing} onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />} onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />
      <DndProvider backend={HTML5Backend}>
        <div className='builder-relative builder-flex builder-flex-1 ' style={{ minHeight: 'calc(100% - 48px)' }}>
          <div className=' builder-flex builder-flex-1 builder-h-full builder-relative' style={{ backgroundColor: '#dee2ec' }}>
            <div className={`builder-flex builder-w-full builder-flex-1 builder-overflow-x-hidden builder-overflow-y-scroll `}>
              <ComponentMenu />
              <div className={`builder-h-fit builder-w-full builder-relative ${lockedState ? 'builder-pointer-events-none' : ''}`}>
                {lockedState && <div className='builder-absolute builder-inset-0 builder-z-9999 builder-bg-white builder-opacity-50 builder-h-full'> </div>}
                {children}
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
    </div>
  );
};

export default EditorTemplate;
