import { useContext, useEffect, useState } from 'react';
import { StateContext } from '../../App';

const excludedPagesFromLockedState = ['/pages'];

const useLockedState = () => {
  const state = useContext(StateContext);

  useEffect(() => {
    // For now to test on the dev env
    // // eslint-disable-next-line no-console
    // console.log('trackingId:', state.session?.sessionId);
    // // eslint-disable-next-line no-console
    // console.log('[pathname]:', window.location.pathname);
    // eslint-disable-next-line no-console
    console.log('users:', state.tracking?.users);
    const users = Object.values(state.tracking?.users).filter((u) => u.route === window.location.pathname);
    if (!users || users.length <= 1 || excludedPagesFromLockedState.includes(window.location.pathname)) {
      setLockedState(false);
      return;
    }
    const firstUserToOpenPage = users.reduce((prev, curr) => (Number(prev.timestamp) < Number(curr.timestamp) ? prev : curr), {});
    if (firstUserToOpenPage.trackingId === state.session?.sessionId) {
      setLockedState(false);
    } else {
      setLockedState(true);
    }
  }, [state.tracking?.users]);

  const [lockedState, setLockedState] = useState(true);

  return {
    lockedState
  };
};

export default useLockedState;
