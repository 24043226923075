import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enTranslation from 'dealer-website-components/dist/translations/en/translation.json';
import frTranslation from 'dealer-website-components/dist/translations/fr/translation.json';
import nlTranslation from 'dealer-website-components/dist/translations/nl/translation.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation },
      nl: { translation: nlTranslation }
    },
    debug: true,
    lng: 'nl', // Default language
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
