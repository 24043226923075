import { useMutation } from 'graphql-hooks';
import { TRACKING_USER_ACTION_TYPES, TRACKING_USER_MUTATION, TRACKING_USER_SUBSCRIPTION, updateIsReleasing, updateTrackingPage, updateTrackingUser } from '../data/tracking';
import { useContext, useEffect, useRef, useState } from 'react';
import { subscriptionClient } from '../../graphqlClient';
import cogoToast from 'cogo-toast';
import { PagesQuery } from '../pages/usePages';
import { pageDeleteHandler, pagesUpdateHandler, pageUpdatSilentHandler } from '../data/actions';
import { DispatchContext, StateContext } from '../../App';

const WS_STATUSSES = { connected: 'connected', disconnected: 'disconnected', connecting: 'connecting' };

export const useTrackingUsers = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const authRef = useRef(state?.session?.authenticated);
  const websiteIdRef = useRef(state?.currentWebsite);

  const isReleasing = state?.tracking?.isReleasing;
  const [wsStatus, setWsStatus] = useState();
  const [qlSubscribed, setQlSubscribed] = useState(false);

  const [trackingUserMutation] = useMutation(TRACKING_USER_MUTATION);

  const setIsReleasing = (value) => {
    updateIsReleasing(dispatch, value);
  };

  const userLeft = () => {
    trackingUserMutation({ variables: { data: { route: undefined, action: TRACKING_USER_ACTION_TYPES.MOVE } } });
  };

  const userMoved = (route) => {
    if (!route) route = window.location.pathname;
    trackingUserMutation({ variables: { data: { route: window.location.pathname, action: TRACKING_USER_ACTION_TYPES.MOVE } } });
  };

  const userPublished = (route) => {
    setIsReleasing(true);
    if (!route) route = window.location.pathname;
    trackingUserMutation({ variables: { data: { route: window.location.pathname, action: TRACKING_USER_ACTION_TYPES.PUBLISH } } });
  };

  const userUpdate = (route) => {
    setIsReleasing(true);
    if (!route) route = window.location.pathname;
    trackingUserMutation({ variables: { data: { route: window.location.pathname, action: TRACKING_USER_ACTION_TYPES.UPDATE } } });
  };

  const userUpdateDone = (route) => {
    setIsReleasing(true);
    if (!route) route = window.location.pathname;
    trackingUserMutation({ variables: { data: { route: window.location.pathname, action: TRACKING_USER_ACTION_TYPES.UPDATE_DONE } } });
  };

  const initSubscription = () => {
    if (!authRef.current) {
      // eslint-disable-next-line no-console
      console.log('USER NOT AUTHENTICATED');
      setQlSubscribed(false);
      return;
    }

    // eslint-disable-next-line no-console
    console.log('INIT SUBSCRIPTION');
    // eslint-disable-next-line no-console
    console.log(window.location.pathname);

    const request = {
      query: TRACKING_USER_SUBSCRIPTION,
      variables: { route: window.location.pathname }
    };

    setQlSubscribed(true);
    subscriptionClient.unsubscribeAll();
    const observable = subscriptionClient.request(request);
    const subscription = observable.subscribe({
      next: (result) => {
        parseSubscriptionMessage(result);
      },
      error: (errors) => {
        parseSubscriptionMessage({ errors });
      },
      complete: () => {
        // eslint-disable-next-line no-console
        console.log('UNSUBSCRIBED...');
        subscription.unsubscribe();
        setQlSubscribed(false);
      }
    });
  };

  const handleConnectedSubscription = () => {
    setWsStatus(WS_STATUSSES.connected);
  };
  const handleReconnectedSubscription = () => {
    setWsStatus(WS_STATUSSES.connected);
    initSubscription();
  };

  const handleConnectingSubscription = () => {
    setWsStatus(WS_STATUSSES.connecting);
  };

  const handleDisconnectedSubscription = () => {
    setWsStatus(WS_STATUSSES.disconnected);
  };

  const handlePageGetSilent = (id, websiteId, onSuccess, onError) => {
    const variables = {
      data: { id, websiteId, isDraft: true }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PagesQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const page = result?.data?.pages?.[0];
        if (!page) {
          cogoToast.error('Get page failed');
          return;
        }

        pageUpdatSilentHandler(dispatch, page.id, page);
      })
      .catch((err) => {
        cogoToast.error(err);
        onError && typeof onError === 'function' && onError(err);
      });
  };

  const handlePagesGet = (ids, websiteId, onSuccess, onError) => {
    const variables = {
      data: { ids, websiteId }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PagesQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const pages = result?.data?.pages;
        if (!pages) {
          cogoToast.error('Get page failed');
          return;
        }

        pagesUpdateHandler(dispatch, pages);
      })
      .catch((err) => {
        cogoToast.error(err);
        onError && typeof onError === 'function' && onError(err);
      });
  };

  const handleOtherUserIsReleasing = () => {
    setIsReleasing(true);
    cogoToast.warn('Publish started');
  };

  const handleOtherUserReleaseDone = () => {
    setIsReleasing(false);
    cogoToast.info('Publish is done');
  };

  const parseSubscriptionMessage = ({ data, errors }) => {
    // For now to test
    if (errors && errors.length > 0) {
      return;
    }
    if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.MOVE || data.trackingUser.action === TRACKING_USER_ACTION_TYPES.LEAVE) {
      updateTrackingUser(dispatch, data.trackingUser.trackingId, data.trackingUser);
    } else if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.PUBLISH) {
      handleOtherUserIsReleasing();
    } else if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.PUBLISH_DONE) {
      handleOtherUserReleaseDone();
    } else if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.UPDATE || data.trackingUser.action === TRACKING_USER_ACTION_TYPES.UPDATE_DONE) {
      updateTrackingPage(dispatch, data.trackingUser);
      if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.UPDATE_DONE) {
        // Get Page
        const routeParts = data.trackingUser.route.split('/');
        const pageId = routeParts[routeParts.length - 1];
        handlePageGetSilent(pageId, websiteIdRef.current);
      }
    } else if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.UPDATE_PAGES) {
      const value = JSON.parse(data.trackingUser.value);
      if (!value) return;
      value.pages_to_delete.forEach((pid) => {
        pageDeleteHandler(dispatch, pid);
      });
      if (value.pages_to_update?.length > 0) {
        handlePagesGet(value.pages_to_update, websiteIdRef.current);
      }
    }
  };

  useEffect(() => {
    websiteIdRef.current = state?.currentWebsite;
  }, [state?.currentWebsite]);

  useEffect(() => {
    authRef.current = state?.session?.authenticated;
    if (state?.session?.authenticated && !qlSubscribed) {
      initSubscription();
    }
  }, [state?.session?.authenticated]);

  useEffect(() => {
    subscriptionClient.on('connected', handleConnectedSubscription);
    subscriptionClient.on('reconnected', handleReconnectedSubscription);
    subscriptionClient.on('reconnecting', handleConnectingSubscription);
    subscriptionClient.on('disconnected', handleDisconnectedSubscription);
  }, []);

  useEffect(() => {
    // Only show popups when user is logged-in
    if (!state?.session?.authenticated) return;
    if (wsStatus === WS_STATUSSES.disconnected) {
      cogoToast.error('Disconnected');
    } else if (wsStatus === WS_STATUSSES.connected) {
      cogoToast.success('Connected');
    } else if (wsStatus === WS_STATUSSES.connecting) {
      // cogoToast.info('Connecting');
    }
  }, [wsStatus]);

  return {
    isReleasing,
    setIsReleasing,
    userMoved,
    userPublished,
    userLeft,
    userUpdate,
    userUpdateDone
  };
};
