import React, { Component, memo, useContext, useMemo, useRef, useState } from 'react';
import * as components from 'dealer-website-components';
import previews from 'dealer-website-components/dist/preview';
import { Link } from 'react-router-dom';

import { componentDetails } from 'dealer-website-components/dist/builder-components-info';
import ImagePreview from './ImagePreview';
import { useDrag } from 'react-dnd';
import { DND_TYPES } from '../../../constants';
import { DispatchContext, StateContext } from '../../../App';
import { pageBlockDeleteByIndexHandler } from '../../data/actions';
import { routes } from '../../data/constants';
import useLockedState from '../../session/useLockedState';

const ComponentView = memo(
  ({ componentName, index, onDrop, context }) => {
    const componentRef = useRef(null);
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const currentState = state.history[state.history.length - 1];

    const memoContext = useMemo(() => context, []);
    const { lockedState } = useLockedState();

    const getComponentTranslatedName = (componentName) => {
      return components?.[componentName]?.human?.['en'] || componentName;
    };

    const getComponentPreview = (componentName) => {
      const NoPreview = () => (
        <div className=' builder-flex builder-flex-col builder-gap-1 builder-justify-center builder-items-center'>
          <i className='fa-regular fa-container-storage' />
          <span>No preview</span>
        </div>
      );
      const NoPreviewError = () => (
        <div className=' builder-flex builder-flex-col builder-gap-1 builder-justify-center builder-items-center'>
          <i className='fa-solid fa-bug' />
          <span>No preview</span>
        </div>
      );

      const PreviewContainer = ({ children }) => {
        return <div style={{ transform: `scale(.4, .4)`, background: 'white' }}>{children}</div>;
      };

      const image = previews[componentName];
      if (image) {
        return <ImagePreview url={image} context={memoContext} />;
      }
      let Preview = NoPreview;
      if (components?.[componentName]?.preview) {
        const Comp = memo(components[componentName].preview, () => true);
        Preview = ({ ...props }) => {
          return (
            <PreviewContainer>
              <Comp {...props} />
            </PreviewContainer>
          );
        };
      }

      const SafeComponent = withErrorBoundary(
        memo(Preview, () => true),
        NoPreviewError
      );
      return <SafeComponent context={memoContext} componentName={componentName} />;
    };

    const [{ isDragging }, drag] = useDrag({
      type: DND_TYPES.LIST_COMPONENT,
      item: () => {
        return { componentName, component: componentDetails[componentName] };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          pageBlockDeleteByIndexHandler(dispatch, currentState.currentPageId, item.index);
        }
        if (item.setDraggingIndex) {
          item.setDraggingIndex(null);
        }
      }
    });
    drag(componentRef);

    return (
      <div
        key={index}
        ref={componentRef}
        title={getComponentTranslatedName(componentName)}
        className={`${
          lockedState ? 'builder-pointer-events-none' : ''
        } builder-px-2 builder-py-4 builder-cursor-pointer builder-border-b builder-border-secondary-darker builder-flex builder-flex-col builder-gap-1 builder-justify-center builder-items-center builder-pb-1`}
      >
        <div className='builder-truncate builder-self-start builder-w-full'>{getComponentTranslatedName(componentName)}</div>
        <div className='builder-w-full builder-h-40 builder-bg-slate-100 builder-p-1 builder-rounded-sm builder-overflow-hidden builder-text-secondary-darker builder-flex builder-justify-center builder-items-center'>
          {getComponentPreview(componentName)}
        </div>
      </div>
    );
  },
  () => true
);

// Handels the preview of the component and has a fallback when something goes wrong rendering te preview without having a white screen
function withErrorBoundary(WrappedComponent, Fallback = () => <div>Error loading component.</div>) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    shouldComponentUpdate(nextProps, nextState) {
      // return true to allow re-render, false to prevent it
      return false;
    }

    componentDidCatch(error, errorInfo) {
      // eslint-disable-next-line no-console
      // console.log('[Error] in preview: ', error, errorInfo);
    }

    render() {
      if (this.state.hasError) {
        return <Fallback />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}

const categoriesOrder = ['general', 'content', 'vehicle', 'features', 'contact', 'seo', 'account', 'purchase'];

const ComponentMenu = (context) => {
  const componentsPerCategory = useMemo(() => {
    const compPerCat = {};
    for (const componentDetailKey in componentDetails) {
      const componentDetail = componentDetails[componentDetailKey];
      if (compPerCat[componentDetail.category]) {
        compPerCat[componentDetail.category].push(componentDetail.name);
        continue;
      }
      compPerCat[componentDetail.category] = [componentDetail.name];
    }

    return compPerCat;
  }, []);

  const [openCategory, setOpenCategory] = useState();

  const handleClickCategory = (cat) => {
    setOpenCategory(openCategory === cat ? undefined : cat);
  };

  return (
    <div className='builder-pointer-events-auto builder-h-full builder-bg-secondary-darker builder-text-white builder-overflow-y-scroll builder-sticky builder-top-0 builder-left-0 builder-bottom-0' style={{ width: 300 }}>
      <Link
        to={routes.PAGES}
        className='builder-z-10000 builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold builder-capitalize builder-sticky builder-top-0 builder-bg-secondary-darker builder-pointer-events-auto hover:builder-bg-black builder-transition-colors'
      >
        <i className='fa fa-chevron-left builder-mr-2' />
        All pages
      </Link>

      {categoriesOrder.map((category, index) => {
        const isOpen = openCategory === category;
        return (
          <div className='builder-flex builder-flex-col'>
            <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold builder-capitalize' onClick={() => handleClickCategory(category)}>
              <i className='fas fa-copy builder-mr-2 builder-text-gray-400 ' />
              {category}
              <div className='builder-ml-auto'>
                {!isOpen && <i className='fa-regular fa-chevron-down' />}
                {isOpen && <i className='fa-regular fa-chevron-up' />}
              </div>
            </div>
            {componentsPerCategory[category] && (
              <div
                className={`builder-flex builder-flex-col builder-bg-secondary-dark builder-text-sm builder-overflow-hidden ${isOpen ? 'builder-max-h-infinite' : 'builder-max-h-0'}`}
                style={{ transition: ` ${isOpen ? 'max-height 5000ms ease-in' : ''} ` }}
              >
                {componentsPerCategory[category].map((comp, index) => (
                  <ComponentView key={index} componentName={comp} index={index} context={context} />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ComponentMenu;
