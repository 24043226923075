import React, { useContext, useEffect, useState } from 'react';
import PageSelectorV2 from './components/PageSelectorV2';
import { useHistory } from 'react-router-dom';

import {
  blockSelectHandler,
  campaignFilterChangeHandler,
  modalOpenHandler,
  pageBlockCampaignChangedHandler,
  pageBlockComponentChangedHandler,
  pageBlockContentChange,
  pageBlockFieldChange,
  pageBlockContentCopy,
  pageBlockContentItemCopy,
  pageBlockContentCopyToAll,
  pageBlockCreationHandler,
  pageBlockDeleteHandler,
  pageBlockHideForCampaignHandler,
  pageBlockOrderChangedHandler,
  pageChange,
  pageMetaChange,
  pagePropertiesChange,
  pageSelectHandler,
  pageTagsChange,
  pageTagAdd,
  pageCategoryChange,
  setLastAction,
  pageBlockDeleteByIndexHandler
} from '../data/actions';
import { cloneDeep, filter, find, findIndex, includes, map } from 'lodash';
import TextInput from './editors/components/TextInput';
import Dropdown from '../../components/common/Dropdown';
import WebsiteContainer from './components/WebsiteContainer';
import BlockEditor from './components/BlockEditor';
import BlockEditorCustom from './components/BlockEditorCustom';
import { MODAL_TYPES } from '../data/reducers';
import PageMetaProperties from './components/PageMetaProperties';
import { DispatchContext, StateContext } from '../../App';
import PageProperties from './components/PageProperties';
import { DefaultPageTypeNames } from '../../constants';
import PageTags from './components/PageTags';
import cogoToast from 'cogo-toast';
import BlockAnalyticsEditor from './components/BlockAnalyticsEditor';
import PageAnalyticsEditor from './components/PageAnalyticsEditor';
import PageOptions from './components/PageOptions';
import PageHeader from './components/PageHeader';
import usePages, { PAGE_STATUSES } from '../pages/usePages';
import { resolveDistributedContent } from '../data/helpers';
import { containsOnlyLowercaseAndHyphens } from '../faq/utils';
import { useTranslation } from 'react-i18next';
import { useTrackingUsers } from '../session/useTrackingUsers';

const FILTERS = {
  ALL: { value: 'View all', key: 'all' },
  NO_CAMPAIGN: { value: 'No campaign', key: 'no_campaign' },
  DURING_CAMPAIGN: { value: 'During campaign', key: 'during_campaign' }
};

const getWidthByDevice = (device) => {
  switch (device) {
    case 'tablet':
      return 778;
    case 'mobile':
      return 375;
    default:
      return '100%';
  }
};

const SlugEditor = ({ page, onChange, error, context }) => {
  if (page.type === DefaultPageTypeNames.home || page.type === 'index') {
    return null;
  }

  if (page.migration) {
    return <span>{page.slug ? page.slug[context.culture] : ''}</span>;
  }

  const handleChange = (value) => {
    let tmpValue = value;
    onChange(tmpValue);
  };

  return (
    <div className='builder-flex builder-items-center builder-mb-4'>
      <div className='builder-text-md builder-text-black builder-font-bold builder-mr-2' style={{ marginTop: -4 }}>
        Slug:
      </div>
      <div className='builder-flex builder-items-center builder-w-full'>
        <TextInput
          placeholder='Slug'
          className='builder-ml-1 builder-flex builder-flex-1 builder-border builder-gray-300 builder-w-full builder-rounded-md'
          value={page.slug ? page.slug[context.culture] : ''}
          onChanged={handleChange}
          errorText={error}
        />
      </div>
    </div>
  );
};

export const CampaignsQuery = `query Campaigns{    
  campaigns {
    id
    name    
  }   
}`;

const Builder = ({ context, currentLanguage, setCurrentLanguage, onChangeEnvironment }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const contextState = useContext(StateContext);
  const history = useHistory();
  const { i18n } = useTranslation();

  const [deviceType, setDeviceType] = useState('desktop');
  const [rightVisible, setRightVisible] = useState(true);
  const [formErrors, setFormErrors] = useState({});

  const { pageStatus, savePage, savePages } = usePages(context);

  const currentState = state.history[state.history.length - 1];
  const currentPage = currentState.pages[currentState.currentPageId] ? currentState.pages[currentState.currentPageId] : undefined;
  const currentBlock = currentPage ? find(currentPage.blocks, (block) => block.id === currentState.currentBlockId) : undefined;
  const availableLanguages = filter(currentState.languages, (l) => l.available);
  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);

  const { userUpdate, userUpdateDone } = useTrackingUsers();

  const [otherUserIsUpdating, setOtherUserIsUpdating] = useState(false);

  const handleSwitchToDraftPage = (id) => {
    history.push(`/builder/${id}`);
    pageSelectHandler(dispatch, id);
  };

  useEffect(() => {
    fetchData();
    isValid(currentPage);
  }, []);

  useEffect(() => {
    history.push(`/builder/${currentState.currentPageId}`);
  }, [currentState.currentPageId]);

  useEffect(() => {
    if (Object.keys(state.tracking.pages).includes(window.location.pathname)) {
      setOtherUserIsUpdating(true);
      cogoToast.info('Pages save started');
    } else if (otherUserIsUpdating) {
      // fetch new page data
      cogoToast.success('Page saved.');
      setOtherUserIsUpdating(false);
    }
  }, [state.tracking.pages]);

  useEffect(() => {
    // Pages changed
    if (!currentPage) return;
    if (currentPage.isDraft) return;
    const draftPage = Object.values(currentState.pages).find((p) => p.url === currentPage.url && p.isDraft);
    if (!draftPage) return;
    handleSwitchToDraftPage(draftPage.id);
  }, [currentState.pages]);

  // temp solution until refactoring pages!
  const [data, setData] = useState({ campaigns: [] });
  // end temp solution

  const handleContentChange = (blockId, field, value, culture) => {
    pageBlockContentChange(dispatch, currentState.currentPageId, blockId, field, value, culture);
  };

  const handleBlockFieldChange = (blockId, field, value) => {
    pageBlockFieldChange(dispatch, currentState.currentPageId, blockId, field, value);
  };

  const handlePageMetaChange = (meta) => {
    pageMetaChange(dispatch, currentState.currentPageId, currentLanguage, meta);
  };

  const handlePageCategoryChange = (category) => {
    pageCategoryChange(dispatch, currentState.currentPageId, category);
  };

  const handlePagePropertiesChange = (type, data) => {
    pagePropertiesChange(dispatch, currentState.currentPageId, type, currentLanguage, data);
  };

  const handlePageTagsChange = (tags) => {
    pageTagsChange(dispatch, currentState.currentPageId, tags);
  };

  const handleAddNewPageTag = (newTag) => {
    pageTagAdd(dispatch, newTag);
  };

  const handleCopyBlockContent = (blockId, copyBlock, culture) => {
    pageBlockContentCopy(dispatch, currentState.currentPageId, blockId, copyBlock, culture);
  };

  const handleCopyBlockContentItem = (blockId, fromCulture, toCulture) => {
    pageBlockContentItemCopy(dispatch, currentState.currentPageId, blockId, fromCulture, toCulture);
  };

  const handleCopyBlockContentToAll = (updatedBlock, culture) => {
    const isLmaPage = includes(currentPage.url, 'dealerGroup/');
    pageBlockContentCopyToAll(dispatch, updatedBlock, isLmaPage);

    const copyPages = cloneDeep(currentState?.pages);
    const { affectedPages } = resolveDistributedContent(copyPages, updatedBlock, isLmaPage);

    savePages(affectedPages, currentWebsite.id, () => {
      setLastAction(dispatch);
      cogoToast.success(`Total ${affectedPages.length} pages updated.`);
    });
  };

  const handlePageChange = (page) => {
    pageChange(dispatch, page);
    isValid(page);
  };

  const handleEditContent = (blockId) => {
    blockSelectHandler(dispatch, blockId);
  };

  const handleDeleteBlock = (blockId) => {
    pageBlockDeleteHandler(dispatch, currentState.currentPageId, blockId);
  };

  const handleBlockUp = (blockId) => {
    const block = find(currentPage.blocks, (block) => block.id === blockId);
    const currentIndex = findIndex(currentPage.blocks, (block) => block.id === blockId);
    pageBlockOrderChangedHandler(dispatch, currentState.currentPageId, currentIndex, currentIndex - 1, block);
  };

  const handleBlockDown = (blockId) => {
    const block = find(currentPage.blocks, (block) => block.id === blockId);
    const currentIndex = findIndex(currentPage.blocks, (block) => block.id === blockId);
    pageBlockOrderChangedHandler(dispatch, currentState.currentPageId, currentIndex, currentIndex + 1, block);
  };

  const handleChangeBlockVariant = (blockId, variant) => {
    pageBlockComponentChangedHandler(dispatch, currentState.currentPageId, blockId, variant);
  };

  const handleDrop = (e) => {
    if (e.removedIndex === null && e.addedIndex === null) return;

    if (e.addedIndex !== null && e.removedIndex === null) {
      pageBlockCreationHandler(dispatch, currentState.currentPageId, e.addedIndex, e.payload);
    } else if (e.addedIndex === null && e.removedIndex !== null) {
      pageBlockDeleteByIndexHandler(dispatch, currentState.currentPageId, e.removedIndex);
    } else if (e.addedIndex !== null && e.removedIndex !== null) {
      pageBlockOrderChangedHandler(dispatch, currentState.currentPageId, e.removedIndex, e.addedIndex, e.payload);
    }
  };

  const slugDomainName = () => {
    if (context.domainName) {
      return `https://${context.domainName}`;
    }

    return '';
  };

  const onChangeSlug = (value) => {
    handlePageChange({
      ...currentPage,
      slug: {
        ...currentPage.slug,
        // [context.culture]: value.replace('/', '') // TODO remove special chars with regex ?
        [context.culture]: value
      }
    });
  };

  const handlePageFieldChange = (field, value) => {
    handlePageChange({
      ...currentPage,
      [field]: value
    });
  };

  const onChangeIndexable = (value) => {
    handlePageChange({
      ...currentPage,
      indexable: value
    });
  };

  const onChangeFilter = (item) => {
    if (item.key === FILTERS.ALL.key) {
      campaignFilterChangeHandler(dispatch, undefined);
    } else if (item.key === FILTERS.DURING_CAMPAIGN.key) {
      campaignFilterChangeHandler(dispatch, FILTERS.DURING_CAMPAIGN.key);
    } else if (item.key === FILTERS.NO_CAMPAIGN.key) {
      campaignFilterChangeHandler(dispatch, FILTERS.NO_CAMPAIGN.key);
    }
  };

  const filterOptions = map(Object.keys(FILTERS), (key) => FILTERS[key]);

  const currentFilterOption = () => {
    let currentFiler = find(filterOptions, (opt) => opt.key === FILTERS.ALL.key);
    if (state.campaignFilter === FILTERS.DURING_CAMPAIGN.key) {
      currentFiler = find(filterOptions, (opt) => opt.key === FILTERS.DURING_CAMPAIGN.key);
    }
    if (state.campaignFilter === FILTERS.NO_CAMPAIGN.key) {
      currentFiler = find(filterOptions, (opt) => opt.key === FILTERS.NO_CAMPAIGN.key);
    }

    return currentFiler;
  };
  const resolveSlugText = () => {
    const domain = slugDomainName();

    if (currentPage.migration) {
      return `${domain}/`;
    }

    if (currentPage.type === DefaultPageTypeNames.blog || currentPage.type === DefaultPageTypeNames.blogPost) {
      return `${domain}/${context.culture}/blog/`;
    }

    return `${domain}/${context.culture}`;
  };

  const validateForm = (page) => {
    let isValid = true;
    let errors = {};

    if (page?.slug[context.culture] && page?.slug[context.culture] !== '' && !containsOnlyLowercaseAndHyphens(page?.slug[context.culture])) {
      errors[`slug`] = 'The slug can only have lowercase chars and -';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = (page) => {
    setFormErrors({});
    const { isValid, errors } = validateForm(page);
    setFormErrors(errors);
    return isValid;
  };

  const fetchData = () => {
    // Temp solution until full refactoring of pages (split up-
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CampaignsQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        setData({
          ...data,
          campaigns: result.data.campaigns
        });
      })
      .catch((err) => {
        cogoToast.error(err);
      });
  };

  const linksMenuWidth = 160;
  let contentWidth = rightVisible ? `calc(100% - 500px - ${linksMenuWidth}px)` : `calc(100% - 50px - ${linksMenuWidth}px)`;
  if (window) {
    contentWidth = rightVisible ? window.innerWidth - 500 - linksMenuWidth : window.innerWidth - 50 - linksMenuWidth;
  }

  const handleSavePage = () => {
    const handleSucces = () => {
      setLastAction(dispatch);
      userUpdateDone();
    };

    const handleError = () => {
      userUpdateDone();
    };

    userUpdate();
    savePage(currentPage, currentWebsite.id, handleSucces, handleError);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
  };

  const isPageSaving = pageStatus === PAGE_STATUSES.SAVING || otherUserIsUpdating;

  return (
    <div className='builder-relative'>
      {isPageSaving && <div className='builder-absolute builder-inset-0 builder-bg-gray-500 builder-animate-pulse builder-bg-opacity-75 builder-pointer-events-auto' style={{ zIndex: 9001 }} />}

      <PageHeader page={currentPage} context={context} onSave={handleSavePage} saving={isPageSaving} errors={formErrors} />

      <div className='builder-w-full builder-relative builder-flex builder-flex-row builder-overflow-scroll'>
        <div className={` builder-w-full builder-px-5 builder-pb-5  `} style={{ width: contentWidth, maxWidth: contentWidth, height: 'calc(100vh - 160px)' }}>
          <div className='builder-flex builder-flex-col builder-justify-center'>
            <div className='builder-mb-5 builder-text-2xl builder-font-semibold'>
              {/*{currentPageName()}*/}
              <PageSelectorV2 pages={currentState.pages} currentLanguage={currentLanguage} value={currentPage ? currentPage.id : ''} onSelectPage={(item) => pageSelectHandler(dispatch, item.key)} />
            </div>
            <div className='builder-flex builder-flex-col builder-bg-white builder-p-5 builder-mb-5'>
              <div className='builder-flex builder-flex-col builder-w-200 builder-mb-5'>
                <div className='builder-flex '>
                  <span className='builder-text-md builder-text-black builder-font-bold'>Language:</span>
                  <span className='builder-uppercase builder-text-black builder-font-bold builder-ml-2 text-builder-md'>{currentLanguage}</span>
                </div>
                {availableLanguages.length > 1 && (
                  <div className='builder-flex builder-mt-4'>
                    <span className='builder-text-md builder-text-black builder-font-bold'>Translations: </span>
                    <div className='builder-flex builder-space-x-2 builder-ml-2'>
                      {map(
                        filter(currentState.languages, (l) => l.code !== currentLanguage && l.available),
                        (language) => {
                          return (
                            <div key={language.code} className='builder-flex builder-items-center builder-uppercase builder-cursor-pointer text-builder-md' onClick={() => handleChangeLanguage(language.code)}>
                              {language.code} <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>

              {currentPage && (
                <React.Fragment>
                  <SlugEditor page={currentPage} onChange={onChangeSlug} error={formErrors?.slug} context={context} />
                  <div className='builder-flex builder-items-center'>
                    <div className='builder-text-md builder-text-black builder-font-bold builder-mr-2' style={{ marginTop: -4 }}>
                      Url:
                    </div>
                    <div className='text-builder-md'>
                      {resolveSlugText()}
                      {currentPage?.slug[context.culture] ? '/' + currentPage?.slug[context.culture] : currentPage.url}
                      {currentPage?.matchPath ? currentPage.matchPath : ''}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>

            {/*<div className={`builder-flex builder-flex-col builder-flex-1 builder-bg-gray-100 builder-overflow-scroll`} style={{width: '100%', maxWidth: 1334}}>*/}
            <div className='builder-flex builder-flex-col builder-flex-1 builder-bg-gray-100 builder-w-full'>
              <div className='builder-flex builder-flex-row builder-justify-between builder-items-center builder-px-5 builder-py-2 builder-bg-white builder-shadow builder-border builder-border-gray-100' style={{ height: 50 }}>
                <div className='builder-flex builder-items-center'>
                  <div className='builder-text-md builder-text-black builder-font-bold builder-mr-2'>Builder components:</div>
                  <div>
                    <Dropdown
                      minWidth={200}
                      borderClassName=''
                      defaultBgColor='builder-gray-400'
                      options={filterOptions}
                      value={currentFilterOption().key}
                      renderItem={(item, i) => {
                        return (
                          <div key={`builder-filter-${i}`} className='builder-flex builder-justify-between builder-px-2 builder-py-3 builder-ml-2 builder-mr-2 builder-text-sm hover:builder-underline' onClick={() => onChangeFilter(item)}>
                            <div className='builder-cursor-pointer'>{item.value}</div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className='builder-flex builder-items-center'>
                  <div
                    className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer ${deviceType === 'desktop' ? 'builder-text-normal' : 'builder-text-gray-300'}`}
                    style={{ width: 30 }}
                    onClick={() => setDeviceType('desktop')}
                  >
                    <i className='fas fa-desktop-alt' />
                  </div>
                  <div
                    className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer  ${deviceType === 'tablet' ? 'builder-text-normal' : 'builder-text-gray-300'}`}
                    style={{ width: 30 }}
                    onClick={() => setDeviceType('tablet')}
                  >
                    <i className='fas fa-tablet-alt' />
                  </div>
                  <div
                    className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer ${deviceType === 'mobile' ? 'builder-text-normal' : 'builder-text-gray-300'}`}
                    style={{ width: 30 }}
                    onClick={() => setDeviceType('mobile')}
                  >
                    <i className='fas fa-mobile-alt' />
                  </div>
                </div>
              </div>

              <div className='builder-flex builder-flex-1 builder-justify-center'>
                <div
                  className='builder-flex'
                  style={{
                    width: getWidthByDevice(deviceType),
                    backgroundColor: '#fafafa'
                  }}
                >
                  <WebsiteContainer
                    page={currentPage}
                    context={context}
                    onDrop={handleDrop}
                    onEditContent={handleEditContent}
                    onDelete={handleDeleteBlock}
                    onMoveUp={handleBlockUp}
                    onMoveDown={handleBlockDown}
                    onChangeBlockVariant={handleChangeBlockVariant}
                    campaignFilter={state.campaignFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`builder-sticky builder-top-0 builder-flex-1`}>
          <div className='builder-flex builder-items-center builder-justify-center builder-bg-gray-100 builder-cursor-pointer builder-p-2 builder-mb-5'>
            <i className='fal fa-stream builder-text-2xl' onClick={() => setRightVisible(!rightVisible)} />
          </div>

          <div className={`builder-flex builder-flex-col builder-space-y-5 builder-transition-all builder-duration-200 builder-ease-in-out ${rightVisible ? 'builder-opacity-100' : 'builder-opacity-0'}`}>
            {currentBlock && currentBlock.contentType !== 'CustomGeneratedBlockInBuilder' && (
              <div className='builder-bg-white builder-shadow-md'>
                <BlockEditor
                  block={currentBlock}
                  currentPage={currentPage}
                  currentLanguage={currentLanguage}
                  contentTypeDefinitions={state.contentTypeDefinitions}
                  onCopyBlock={(blockId, copyBlock, currentLanguage) => handleCopyBlockContent(blockId, copyBlock, currentLanguage)}
                  onCopyBlockContentItem={(blockId, fromCulture, toCulture) => handleCopyBlockContentItem(blockId, fromCulture, toCulture)}
                  onCopyBlockToAll={(block, currentLanguage) => handleCopyBlockContentToAll(block, currentLanguage)}
                  isSaving={pageStatus === PAGE_STATUSES.SAVING}
                  pages={currentState.pages}
                  campaigns={data['campaigns']}
                  context={context}
                  onContentChange={(blockId, field, value, currentLanguage) => handleContentChange(blockId, field, value, currentLanguage)}
                  onCampaignChange={(blockId, campaignId) => pageBlockCampaignChangedHandler(dispatch, currentPage.id, blockId, campaignId)}
                  onHideForCampaignChange={(blockId, hide) => pageBlockHideForCampaignHandler(dispatch, currentPage.id, blockId, hide)}
                />
              </div>
            )}

            {currentBlock && currentBlock.contentType === 'CustomGeneratedBlockInBuilder' && (
              <div className='builder-bg-white builder-shadow-md'>
                <BlockEditorCustom
                  block={currentBlock}
                  campaigns={data['campaigns']}
                  context={context}
                  onEdit={(block) => modalOpenHandler(dispatch, MODAL_TYPES.BLOCK_CREATE)} // TODO EDIT TO BLOCK EDIT
                  onCampaignChange={(blockId, campaignId) => pageBlockCampaignChangedHandler(dispatch, currentPage.id, blockId, campaignId)}
                  onHideForCampaignChange={(blockId, hide) => pageBlockHideForCampaignHandler(dispatch, currentPage.id, blockId, hide)}
                />
              </div>
            )}

            {currentBlock && (
              <div className='builder-bg-white builder-shadow-md'>
                <BlockAnalyticsEditor block={currentBlock} onBlockFieldChange={handleBlockFieldChange} context={context} />
              </div>
            )}

            {currentPage && (
              <div className='builder-bg-white builder-shadow-md'>
                <PageMetaProperties
                  page={currentPage}
                  meta={currentPage.meta ? find(currentPage.meta, (meta) => meta.language === currentLanguage) : undefined}
                  onChangeMeta={handlePageMetaChange}
                  onChangeIndexable={onChangeIndexable}
                  context={context}
                />
              </div>
            )}

            {currentPage && (
              <div className='builder-bg-white builder-shadow-md'>
                <PageOptions page={currentPage} category={currentPage?.category} onChangeCategory={handlePageCategoryChange} context={context} />
              </div>
            )}

            {currentPage && (
              <div className='builder-bg-white builder-shadow-md'>
                <PageAnalyticsEditor page={currentPage} onPageFieldChange={handlePageFieldChange} context={context} />
              </div>
            )}

            {currentPage && (
              <div className='builder-bg-white builder-shadow-md'>
                <PageProperties page={currentPage} properties={currentPage.properties ? filter(currentPage.properties, (prop) => prop.language === currentLanguage) : undefined} onChange={handlePagePropertiesChange} context={context} />
              </div>
            )}

            {currentPage && currentPage.type === DefaultPageTypeNames.blogPost && (
              <div className='builder-bg-white builder-shadow-md'>
                <PageTags value={currentPage.tags} pageTags={state.pageTags} onChange={handlePageTagsChange} onAddNewTag={handleAddNewPageTag} context={context} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Builder;
