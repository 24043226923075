import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '../../features/data/constants';
import { DispatchContext, StateContext } from '../../App';
import { LoadingIndicator } from './index';
import { useQuery } from 'graphql-hooks';
import { setTrackingUsers, TRACKING_STATE_QUERY } from '../../features/data/tracking';
import { useTrackingUsers } from '../../features/session/useTrackingUsers';

const PrivateRoute = ({ children, context, ...rest }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { loading, error, data } = useQuery(TRACKING_STATE_QUERY);
  const { setIsReleasing } = useTrackingUsers();
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('resp: ', data);
    if (data?.trackingState) {
      const users = data.trackingState.trackingUsers.reduce((acc, item) => {
        acc[item.trackingId] = item;
        return acc;
      }, {});

      setTrackingUsers(dispatch, users);
      if (data?.trackingState?.isPublishing) {
        setIsReleasing(true);
      }
    }
  }, [data]);

  if (state.session === undefined) {
    return <LoadingIndicator />;
  }

  const renderRoute = ({ location }) => {
    if (state.session.authenticated) {
      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: routes.LOGIN,
            state: { from: location }
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderRoute} />;
};

export default PrivateRoute;
