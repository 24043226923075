import React, { useContext, useState } from 'react';
import { includes, map, orderBy } from 'lodash';
import { Container, Draggable } from 'react-smooth-dnd';
import { getComponentsForCategory, getHumanText, getPreviewImage } from '../../../utils';
import { getCustomBlockContent } from './BlockComponentCustom';
import { Link, useHistory } from 'react-router-dom';
import { routes } from '../../data/constants';
import { customBlockSelectHandler, modalCloseHandler, modalOpenHandler } from '../../data/actions';
import { MODAL_TYPES } from '../../data/reducers';
import { DispatchContext, StateContext } from '../../../App';
import { DefaultPageTypeNames } from '../../../constants';

const onlyAdmin = ['account'];

const ComponentCategories = [
  { id: 'general', description: 'General' },
  { id: 'content', description: 'Content' },
  { id: 'vehicle', description: 'Vehicle' },
  { id: 'features', description: 'Features' },
  { id: 'contact', description: 'Contact' },
  { id: 'seo', description: 'SEO' },
  // {id: 'blog', description: 'Blog'},
  { id: 'account', description: 'Account' },
  { id: 'purchase', description: 'Purchase' },
  { id: 'custom', description: 'Custom' }
];

export const Menu = ({ context, customBlocks }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [category, setCategory] = useState(undefined);
  const [dragStarted, setDragStarted] = useState(false);
  const isSuperUser = state.session.user.isSuperUser;
  const history = useHistory();

  const categoryComponents = getComponentsForCategory(category);

  const componentsWithPreview = map(categoryComponents, (comp) => ({
    ...comp,
    preview: getPreviewImage(comp.name, context)
  }));

  const onCreatePage = (pageType) => {
    modalOpenHandler(dispatch, MODAL_TYPES.PAGE_CREATE, { type: pageType });
  };

  const onCreateCustomBlock = () => {
    // modalOpenHandler(dispatch, MODAL_TYPES.BLOCK_CREATE);
    history.push(`${routes.CUSTOM_BLOCK}`, {
      language: context.culture
    });
  };

  const onEditCustom = (customBlock) => {
    // customBlockSelectHandler(dispatch, customBlock.name);
    // modalOpenHandler(dispatch, MODAL_TYPES.BLOCK_CREATE);
    history.push(`${routes.CUSTOM_BLOCK}/${customBlock.name}`, {
      language: context.culture
    });
    modalCloseHandler((dispatch, MODAL_TYPES.BLOCK_CREATE));
  };

  return (
    <div className='builder-h-full builder-bg-secondary-darker builder-text-white builder-overflow-y-scroll' style={{ width: 160 }}>
      {/*BLOCKS*/}
      {isSuperUser && (
        <Link to={routes.BLOCKS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fa fa-cube builder-mr-2 builder-text-gray-400' />
          Blocks
        </Link>
      )}
      {/*PAGES*/}
      <div className='builder-flex builder-flex-col'>
        <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fas fa-copy builder-mr-2 builder-text-gray-400' />
          Pages
        </div>
        <div className='builder-flex builder-flex-col builder-bg-secondary-dark builder-text-sm'>
          <Link to={routes.PAGES} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            All pages
          </Link>
          <div className='builder-px-3.5 builder-mb-3.5 builder-cursor-pointer' onClick={() => onCreatePage()}>
            Add new
          </div>
        </div>
      </div>

      <div className='builder-flex builder-flex-col'>
        <Link to={routes.CUSTOM_BLOCKS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fal fa-code builder-mr-2 builder-text-gray-400' />
          Custom blocks
        </Link>
      </div>

      <div className='builder-flex builder-flex-col'>
        <Link to={routes.SEO_OVERVIEW} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fal fa-analytics builder-mr-2 builder-text-gray-400' />
          Seo
        </Link>
      </div>

      <div className='builder-flex builder-flex-col'>
        <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fal fa-thumbtack builder-mr-2 builder-text-gray-400' />
          Blog
        </div>
        <div className='builder-flex builder-flex-col builder-bg-secondary-dark builder-text-sm'>
          <Link to={routes.BLOG} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            All blog posts
          </Link>
          <div className='builder-px-3.5 builder-mb-3.5 builder-cursor-pointer' onClick={() => onCreatePage(DefaultPageTypeNames.blogPost)}>
            Add new
          </div>
        </div>
      </div>

      <Link to={routes.CAMPAIGNS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
        <i className='fab fa-adversal builder-mr-2 builder-text-gray-400' />
        Campaigns
      </Link>

      <Link to={routes.ADVERTISEMENTS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
        <i className='fab fa-adversal builder-mr-2 builder-text-gray-400' />
        Advertisements
      </Link>

      <div className='builder-flex builder-flex-col'>
        <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fal fa-question-square builder-mr-2 builder-text-gray-400' />
          FAQ
        </div>
        <div className='builder-flex builder-flex-col builder-bg-secondary-dark builder-text-sm'>
          <Link to={routes.FAQ_OVERVIEW} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            Overview
          </Link>
          <Link to={routes.FAQ_TOPICS} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            Topics
          </Link>
        </div>
      </div>

      {isSuperUser && (
        <Link to={routes.VEHICLE_FILTERS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fas fa-search builder-mr-2 builder-text-gray-400' />
          Filters
        </Link>
      )}

      {isSuperUser && (
        <Link to={routes.SETTINGS_DEALERSHIPS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fas fa-home builder-mr-2 builder-text-gray-400' />
          Dealerships
        </Link>
      )}

      <Link to={routes.SETTINGS_GENERAL_CONFIG} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
        <i className='fas fa-cog builder-mr-2 builder-text-gray-400' />
        Settings
      </Link>
    </div>
  );
};

const MenuItem = ({ id, text, onMouseEnter, onClick, isLast, onCreateCustomBlock }) => {
  const isCustom = id === 'custom';
  return (
    <div className={`builder-flex builder-flex-col builder-justify-center builder-px-3.5 builder-mt-3.5 ${isLast && 'builder-mb-3.5'}`}>
      <div className={`builder-flex builder-items-center`} key={`menu_item_${id}`} onMouseEnter={onMouseEnter} onClick={onClick}>
        <span>{text}</span>
      </div>
    </div>
  );
};
