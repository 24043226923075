import React, { useContext } from 'react';
import Modal from 'react-modal';
import { useSignOut } from '../authentication/useSignout';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import { modalCloseHandler } from '../data/actions';
import { MODAL_TYPES } from '../data/reducers';
import { DispatchContext } from '../../App';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '33%'
  },
  overlay: {
    zIndex: 9999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const InactivityModal = ({ isOpen, timeRemaining, onClose = () => null, context }) => {
  const dispatch = useContext(DispatchContext);
  const { signOut } = useSignOut();

  const handleLeave = () => {
    signOut();
  };
  const handleClose = () => {
    modalCloseHandler(dispatch, MODAL_TYPES.INACTIVITY);
    onClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles} contentLabel='Inactivity Modal' ariaHideApp={false} appElement={document.getElementById('root') || undefined}>
        <div className='builder-text-black'>
          <h3>You are inactive</h3>

          <div className='builder-py-8 builder-flex builder-flex-col builder-justify-center builder-text-center'>
            <p>You have 2 minutes time to respond. </p>
            <p>
              After 2 minutes of inactivity, you'll be signed out and <b>all you unsaved changes will we lost!</b>{' '}
            </p>
            <div />
          </div>

          <div className='builder-flex builder-justify-center builder-mb-6' style={{ fontSize: 16 }}>
            <div className='builder-flex builder-flex-col builder-items-center'>
              <div className='time'>{timeRemaining}</div>
              <div>seconds</div>
            </div>
          </div>

          <div className='builder-flex builder-justify-center builder-mt-6'>
            <ButtonWithIndicator loading={false} onClick={handleLeave} text='Leave builder' />

            <ButtonWithIndicator loading={false} onClick={handleClose} text='Continue working' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' className='builder-ml-4' />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InactivityModal;
