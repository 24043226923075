import { useContext } from 'react';
import { find, map, values } from 'lodash';
import { setLastAction } from '../data/actions';
import cogoToast from 'cogo-toast';
import { DispatchContext, StateContext } from '../../App';
import { useMutation } from 'graphql-hooks';
import { ExecuteMutation, MoveDraftToProductionMutation, SiteUpdateMutation } from '../data/graphql';
import { mapPage } from '../pages/usePages';

export const mapPages = (pagesData, isCopy = false, website_id = null) => {
  return map(values(pagesData), (page) => mapPage(page, isCopy, website_id));
};

export function UseBuilderActions(language) {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const [saveMutation, { loading: saveLoading }] = useMutation(SiteUpdateMutation);
  const [executeMutation, { loading: executeLoading }] = useMutation(ExecuteMutation);
  const [moveDraftToProductionMutation, { loading: moveDraftToProductionLoading }] = useMutation(MoveDraftToProductionMutation);

  const updateWebsite = () => {
    const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
    const pagesData = mapPages(currentState.pages, false, currentWebsite.id);

    const customBlocksData = map(currentState.customBlocks, (block) => {
      return {
        id: block.name,
        description: block.description,
        content: block.content
      };
    });

    // const campaignsData = map(currentState.campaigns, (campaign) => {
    //   return {
    //     uuid: campaign.id,
    //     name: campaign.name,
    //     start: dayjs(campaign.start).format('YYYY-MM-DD'),
    //     stop: dayjs(campaign.stop).format('YYYY-MM-DD'),
    //     isDraft: campaign.isDraft,
    //     disabled: campaign.disabled | false
    //   };
    // });

    // const advertisementGroupsData = map(currentState.advertisementGroups, (group) => {
    //   return {
    //     id: group.id,
    //     name: group.name,
    //     isDraft: group.isDraft,
    //     disabled: group.disabled
    //   };
    // });

    // const advertisementsData = map(currentState.advertisements, (advertisement) => {
    //   return {
    //     id: advertisement.id,
    //     description: advertisement.description,
    //     content: advertisement.content,
    //     groups: advertisement.groups,
    //     isDraft: advertisement.isDraft,
    //     disabled: advertisement.disabled,
    //     filters: JSON.stringify(advertisement.filters)
    //   };
    // });

    // const vehicleSearchFiltersData = map(currentState.vehicleSearchFilters, (f) => {
    //   return {
    //     id: f.id,
    //     name: JSON.stringify(f.name),
    //     type: f.type,
    //     filters: JSON.stringify(f.filters),
    //     isDraft: f.isDraft,
    //     disabled: f.disabled
    //   };
    // });

    const pageContent = map(currentState.pageContentSEO, (content) => {
      return {
        ...content,
        filters: JSON.stringify(content.filters),
        text: JSON.stringify(content.text)
      };
    });

    saveMutation({
      variables: {
        data: {
          website: {
            uuid: currentWebsite.id,
            name: currentWebsite.name,
            pages: pagesData
          },

          pageContent: pageContent,
          customBlocks: customBlocksData
          //campaigns: campaignsData,

          //advertisementGroups: advertisementGroupsData,
          //advertisements: advertisementsData,
          //vehicleSearchFilters: vehicleSearchFiltersData
        }
      }
    })
      .then((res) => {
        const { ok } = res.data.siteUpdate;
        if (ok) {
          setLastAction(dispatch);
          cogoToast.success('Layout saved!');
        }
      })
      .catch((error) => {
        cogoToast.error('Error saving layout!');
      });
  };

  const executeScript = (action, project, payload = {}) => {
    executeMutation({
      variables: {
        action: action,
        project: project,
        payload: JSON.stringify(payload)
      }
    }).then((r) => {
      const { execute } = r.data;
      if (execute.ok) {
        setLastAction(dispatch);
      } else {
        cogoToast.error('Error!');
      }
    });
  };

  const moveDraftToProduction = async (websiteId, pageIds) => {
    await moveDraftToProductionMutation({
      variables: {
        data: {
          websiteId,
          pageIds
        }
      }
    });
  };

  return {
    dispatch,
    updateWebsite,
    moveDraftToProduction,
    executeScript,
    loading: saveLoading || executeLoading,
    moveDraftToProductionLoading
  };
}

export default UseBuilderActions;
