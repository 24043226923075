// Object with al names of pagetypenames, this list cannot be modified!
// Names are fixed because of backword compatibilty an historical reasaons..
// Page types are dynamic in the DB, but this list is the fixed "default" defined ones!
// Implementation can define other types, but builder is not impacted here
export const DefaultPageTypeNames = {
  404: '404',
  about: 'about',
  account: 'account',
  accountCarAgent: 'accountCarAgent',
  accountChangePassword: 'accountChangePassword',
  accountFavorites: 'accountFavorites',
  accountOverview: 'accountOverview',
  accountProfile: 'accountProfile',
  accountPurchases: 'accountPurchases',
  blog: 'blog',
  blogPost: 'blogPost',
  contact: 'contact',
  contactDetail: 'contactDetail',
  faq: 'faq',
  faqCategory: 'faqCategory',
  faqTopic: 'faqTopic',
  favorites: 'favorites',
  forgotPassword: 'forgotPassword',
  home: 'home',
  login: 'login',
  privacy: 'privacy',
  purchase: 'purchase',
  purchaseFinancingCoContractorAuthenticate: 'purchaseFinancingCoContractorAuthenticate',
  purchaseFinancingContractorAuthenticate: 'purchaseFinancingContractorAuthenticate',
  purchaseLogin: 'purchaseLogin',
  purchaseRegistration: 'purchaseRegistration',
  registration: 'registration',
  resetPassword: 'resetPassword',
  services: 'services',
  vehicleCompare: 'vehicleCompare',
  vehicleDetail: 'vehicleDetail',
  vehicleDetailMakeModel: 'vehicleDetailMakeModel',
  vehicleReservation: 'vehicleReservation',
  vehicleSearchAgent: 'vehicleSearchAgent',
  vehicles: 'vehicles',
  custom: 'custom'
};

export const DND_TYPES = {
  LIST_COMPONENT: 'LIST_COMPONENT',
  PAGE_COMPONENT: 'PAGE_COMPONENT',
  CATEGORY: 'CATEGORY',
  GROUP: 'GROUP',
  QUESTION: 'QUESTION'
};
