import React, { useContext } from 'react';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { modalOpenHandler, undoHandler } from '../../data/actions';
import { DispatchContext, StateContext } from '../../../App';
import { MODAL_TYPES } from '../../data/reducers';

const HeaderMenu = ({ isReleasing, onChangeEnvironment }) => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);

  const handleUndo = () => {
    undoHandler(dispatch);
  };

  const renderProductionButton = () => {
    if (state?.configuration?.builder?.publish !== undefined) {
      const publishEnabled = Boolean(state.configuration.builder.publish);
      if (!publishEnabled) {
        return null;
      }
    }

    return <ButtonWithIndicator loading={isReleasing} disabled={isReleasing} onClick={() => modalOpenHandler(dispatch, MODAL_TYPES.PUBLISH_WEBSITE)} icon='fal fa-cloud-upload' text='Publish' />;
  };

  return (
    <div className='builder-flex builder-flex-1 builder-items-center builder-justify-end builder-p-2 builder-cursor-pointer'>
      <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2' onClick={handleUndo}>
        <i className='fal fa-undo' />
      </div>

      {renderProductionButton()}
    </div>
  );
};

export default HeaderMenu;
